<template>
        <div class="chart-container">
                <apexchart :key="wallets.length" class="apex" type="donut" width="100%" height="100%" :options="chartOptions" :series="series" />
                <!-- apextoman click change to false but back-end isnt ready so i turned it off for now -->
                <div @click="apexToman=true" :class="{'priceSelect':apexToman}" class="pricetype tetherp">
                    <img src="/coins/TETHER.png" alt="">
                </div>
                <div @click="apexToman=true" :class="{'priceSelect':!apexToman}" class="pricetype tomanp">
                    <img src="/coins/TOMAN.png" alt="">
                </div>
        </div>
</template>

<script>
    export default {
        name:'ApexChart',
        props:['wallets'],
        data() {
            return {
                change:0,
                series:[],
                // wallets:[],
                apexToman:true,
                chartOptions: {
                chart: {
                    width: '50px',
                    fontFamily: 'shabnam',
                },

                // responsive: [{
                //     breakpoint: 400,
                //     options: {
                //         chart: {
                //             width: '150px',
                //         },
                //     },
                // }],

                colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
                theme: {
                    mode: 'light',
                    palette: 'palette2'
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                labels: [],
                dataLabels: {
                    style: {
                        fontFamily: 'shabnam'
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                            size: '65%',
                            background: 'white',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'var(--tooDark)',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 400,
                                    color: '#3D49CC',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'black',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                }
                },
            }
        },
        watch: {
            'wallets'(){
                this.chart();
            }
        },
        methods:{
            chart() {
                    let index = 0
                    this.wallets.forEach((e) => {
                            this.chartOptions.labels[index] = {
                                'name': this.$coinLabel[e.relatedCoin],
                                'value': e.wholePriceOfCredit || 0
                            }
                            this.series[index] = Math.abs(e.wholePriceOfCredit) || Math.abs(e.totalBalance) ||  0
                            index++
                        })
                        ++this.change
            },
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (max-width:400px) {
    .chart-container{
        width: 200px;
        height: 200px;
    }
}
.chart-container {
    position: relative;
    display: flex;
    border-radius: 50%;
    background: white;
    width: 268px;
    height: 268px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

}
.apex{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: 20px;
}
.priceSelect{
    img{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);

    }
}
.pricetype{
    position: absolute;
    bottom: 10px;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
        width: 80%;
        height: 80%;
    }
}
.tetherp{
    right: 0;
}
.tomanp{
    left: 0;
}
</style>