<template>
<div class="wallet-container">
    <div class="walletList">
        <div>
            
            
            <div class="tr-head">
                <div class="head-title-container">
                    <div v-for="(item , index) in heade" :key=index :class="item.class">
                        {{item.title}}
                    </div>
                </div>
                <div class="empty"></div>
            </div>
            <div @click.self="reD(wallet.relatedCoin)" class="tr-body" v-for="(wallet , index) in wallets.filter(e => e[0]!=='TOMAN')" :key="index">
                <div class="info-contianer">
                    <div class="name-container">
                        <img class="list-img" :src="'/coins/'+ wallet.relatedCoin + '.png'">
                        <span>
                            {{$coinLabel[wallet.relatedCoin]}}
                        </span>
                        <span class="ENname">
                            {{$coinUnit[wallet.relatedCoin]}}
                        </span>
                    </div>
                    <div class="balance">
                        {{$toLocal(wallet.credit || 0)}} <span class="ENname">({{$coinUnit[wallet.relatedCoin]}})</span>
                    </div>
                    <div class="value">
                        {{$toLocal(wallet.wholePriceOfCredit) || 0}} <span class="ENname">(تومان)</span>
                    </div>
                </div>
                <div class="btns-container">
                    <div>
                        <span class="opration" @click="$router.push('deposit-coin/'+wallet.relatedCoin)">
                            واریز
                        </span>
                    </div>
                    <div>
                        <span class="opration" @click="$router.push('withdrawal-coin/'+wallet.relatedCoin)">
                            برداشت
                        </span>
                    </div>
                    <div class="trade">
                        <a @click="$router.push('/advancetrade/TOMAN/BITCOIN')">
                            <span class="opration">
                                {{wallet.relatedCoin !== 'TOMAN'? 'معامله' : '  '}}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="left-side">
        <ApexChart :wallets="wallets"/>
        <div class="btn-container">
            <button class="deposit" @click="$router.push('rial-deposit')">
                واریز
            </button>
            <button class="withdraw" @click="$router.push('rial-withdrawal')">
                برداشت
            </button>
        </div>
        <div class="btn-container">
            <span class="wealth-title" :class="{'active-title' : sum}" @click="sum = true">
                پراکندگی دارایی
            </span>
            <span class="wealth-title" :class="{'active-title': !sum}" @click="sum = false">
                تجمیع دارایی
            </span>
        </div>
        <template v-if="sum">
            <div v-for="(wallet,index) in balanceWallet" :key="index" class="wealth-coin">
                <div class="walletCoin-container">
                    <img class="list-img" :src="'/coins/'+ wallet.relatedCoin+ '.png'">
                    <span>
                        {{$coinLabel[wallet.relatedCoin]}}
                    </span>
                </div>
                <span>
                    {{$toLocal(wallet.percent,4)}}%
                </span>
            </div>
        </template>
        <template v-if="!sum">
            <div style="justify-content: center;" class="wealth-coin">
                <img class="list-img" :src="'/coins/'+ 'TOMAN'+ '.png'">
                <span> تومان </span>
                <span> {{$toLocal(balancePrice,0)}} </span>
            </div>
        </template>
        
    </div>
</div>
</template>

<script>
import ApexChart from './ApexChart.vue';
export default {
    name: 'Wallet',
    components:{
        ApexChart,
    },
    data() {
        return {
            sum: true,
            wallets: [],
            balanceWallet:[],   
            balancePrice:0,
            toman:{},
            tether:{},
            heade: [{
                    title: "ارز",
                    class: ""
                },
                {
                    title: "موجودی",
                    class: "balance"
                },
                {
                    title: "ارزش",
                    class: "value"
                },
            ]
        }
    },
    methods: {
            reD(val){
                console.log(val);
                if(val !== 'TETHER'){
                    this.$router.push('/advancetrade/TETHER/'+val)
                } else{
                    this.$router.push('/advancetrade/TOMAN/'+val)
                }
            },
            async getWallets() {
                    this.state.loading=true
                    const res = await this.$axios.get('/wallets/customer-addresses')
                    let entireRes = Object.entries(res)
                    this.sortWallets(entireRes);
            },
            sortWallets(e){
                const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN'];
                e.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.tether = e.filter(a => a[0] == 'TETHER')[0][1]
                this.toman = e.filter(a => a[0] == 'TOMAN')[0][1]
                this.wallets = e.map(a => {
                        let relatedCoin = a[0]
                        if(a.relatedCoin) {
                            a=a[1]
                        }else{
                            a=a[1]
                            a.relatedCoin=relatedCoin
                        }
                        return a
                        })
                    // this.wallets=this.wallets.filter(wallet =>  wallet.relatedCoin !== 'TOMAN')
                    this.wallets.sort((a,b) => b.credit - a.credit)
                    this.creditPercent();
            },
            creditPercent(){
                this.balanceWallet = this.wallets.filter(a => a.credit > 0)
                this.balanceWallet = this.balanceWallet.filter(wallet =>  wallet.relatedCoin !== 'TOMAN')
                this.balanceWallet.map(e => {
                    this.balancePrice += e.wholePriceOfCredit;
                });
                this.balanceWallet.map(e => {
                    e.percent = (e.wholePriceOfCredit / this.balancePrice) * 100
                });
                console.log(this.balancePrice);

            },
    },
    async mounted() {
        await this.getWallets();
    },
}
</script>

<style lang="scss" scoped>
.walletCoin-container{
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}
.info-contianer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
    div{
        width: 150px;
        text-align: start;
    }
}
.btns-container{
    display: flex;
    flex-direction: row;
    gap: 24px
}
.priceSelect{
    img{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);

    }
}
.wallet-container {
    display: flex;
    justify-content: space-between;
}

.wallet {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    /* max-width: 995px; */
    width: 100%;
}

.wallet-title {
    height: 68px;
    display: flex;
    box-sizing: border-box;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    // background: white;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 20px;
}

.wallet-title-p {
    /* font-size: 22px; */
    font-size: clamp(16px, 1vw, 22px);
    margin: 0;
}

.wallet-title-number {
    /* font-size: 22px; */
    font-size: clamp(16px, 1vw, 22px);
    color: #3D49CC;
    margin: 0;
}

.wallet-info {
    // background: #FFFFFF;
    border-radius: 3px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 20px;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.info-img {
    width: 50px;
    height: 50px;
}

.numbers-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    flex-grow: 1;
}

.numbers-container p {
    margin: 0;
}

.first-info {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-grow: 1;
}

.number {
    font-size: 18px;
    color: #3D49CC;
}

.number-title {
    font-size: 14px;
    color: rgba(64, 64, 64, 0.5)
}

.info-btn {
    color: rgba(61, 73, 204, 0.5);
    border: none;
    width: 98px;
    height: 44px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 3px;
}

.info-btn:hover {
    
    color: var(--primary-color);
}

.btns {
    display: flex;
    column-gap: 10px;
    flex-grow: 1;
    justify-content: flex-end;
}

.walletList {
    // background: #FFFFFF;
    border-radius: 3px;
    // height: 471px;
    box-sizing: border-box;
    padding: 10px 20px;
    flex-grow: 1;
    /* overflow: scroll; */
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.tr-head {
    margin-right: 20px;
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: space-between;
    .head-title-container{
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div{
            width: 150px;
        }
    }
}

.tr-head div {
    color: #999999;
    font-size: 12px;
    width: 50px;
}

.tr-head td:first-of-type {
    text-align: right;
    padding-right: 10px;
}

.tr-body {
    font-size: 14px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 0 10px;
    height: 64px;
    align-items: center;
    column-gap: 20px;
}

.list-img {
    width: 30px;
    height: 30px;
}

.tr-body td:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 42px;
}

.opration {
    font-size: clamp(12px,1vw,14px);
    font-weight: 400;
    color: #888E95;
    cursor: pointer;
    width: auto;
}

.opration:hover {
    color: var(--primary-color);
}

.ENname {
    color: #888E95;
}

.name-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.left-side {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}


.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deposit {
    background: #21A315;
    border-radius: 8px;
    width: 125px;
    height: 40px;
    color: white;
}

.withdraw {
    background: #CF0F0F;
    border-radius: 8px;
    border-radius: 8px;
    width: 125px;
    height: 40px;
    color: white;
}

.wealth-title {
    font-size: 14px;
    color: #4F4F4F;
    cursor: pointer;
}

.active-title {
    color: #D09C0A;
}

.wealth-coin {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 10px 0;
    justify-content: space-between;
    span {
        font-size: 14px;
    }
}
@media only screen and (max-width:1300px) {
    .info-contianer , .head-title-container{
        width: 70% !important;
    }
}
@media only screen and (max-width: 1500px) {
    .wallet-container {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width:700px) {

    .ENname,
    .list-img,
    .balance,
    .value,
    .withdrawal,
    .deal {
        display: none;
    }
}
@media only screen and (max-width:400px) {
    .chart-container{
        width: 200px;
        height: 200px;
    }
    .tr-body{
        flex-direction: column;
        gap: 8px;
        padding: 10px;
    }
}

</style>
